import bootstrap from '@/common-main';
import App from './cupra-app.vue';
import store from './cupra-store';
import router from './cupra-router';

const progressbarOptions = {
  color: '#003c4a',
};

bootstrap({
  App, store, router, progressbarOptions,
});
