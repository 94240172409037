import Vue from 'vue';
import Vuex from 'vuex';
import CommonStore from '@/common-store';
import ApiStore from './cupra-api';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: { ...CommonStore.modules, Api: ApiStore },
  plugins: [
    ...CommonStore.plugins,
  ],
});

export default store;
